<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />

    <b-form
        class="p-2"
        @submit.prevent="createAppointment('create')"

    >
      <b-form-group
          label="Tipo de cita"
      >
        <b-form-select
            v-model="appointment.source"
            @change="verifySource()"
            :options="sources"
        />
      </b-form-group>

      <b-form-group label="Motivo de cita" label-for="h-email">
        <v-select
            v-model="reason_appoinment"
            @input="changeReason()"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="reasons"
        />
      </b-form-group>

      <b-form-group

          label="Pacientes"
          label-for="h-email"
      >
        <vue-autosuggest
            :suggestions="patients"
            :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Seleccionar'}"
            @input="getPatientSelect"
            @selected="patientVerify"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>
      </b-form-group>
      <b-form-group
          v-if="verified_treatment_medicals"
          label="Cita con"
          label-for="h-email"
      >
        <v-select
            @input="groupsVerify()"
            v-model="select_group"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="groups"
        />
      </b-form-group>

      <b-form-group
          v-if="verified_treatment_medicals"
          :label="place_active ? 'Salas' : 'Medicos'"
          label-for="h-email"
      >
        <v-select
            @input="medicalVerify()"
            v-model="select_medical"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="medicals"
        />
      </b-form-group>
      <b-form-group
          v-if="verified_treatment_medicals"
          label="Ciclo"
      >
        <b-form-input
            @keypress="isNumber($event)"
            v-model="appointment.cycle"
            placeholder="Ciclo"
        />
      </b-form-group>

      <b-form-group
          v-if="!place_active"
          label="Opciones cita"
          label-for="h-email"
      >
        <v-select
            @input="typesVerify()"
            v-model="select_types"
            label="value"
            :options="types"
        />
      </b-form-group>
      <b-form-group
          label=""
          label-for="start-date"
      >
        <label for=""><span style="margin-left: 20px">Hora de inicio</span> <span style="margin-left: 20px">~</span>
          <span style="margin-left: 20px">Hora de salida</span></label>
        <date-picker
            v-model="value2"
            format="YYYY-MM-DD HH:mm"
            type="datetime"
            :show-time-header="true"
            @close="changeDate()"
            style="width: 100%"
            confirm
            range
            :disabled-date="(date) => date <= disabledBefore"
            placeholder="Seleccione las fechas"
            :bootstrap-styling="true"
        >
          <template #header>
            <div style="text-align: left">
              <b-row>
                <b-col cols="6">
                  <h3 style="text-align: center"> Inicio</h3>

                </b-col>
                <b-col cols="6">
                  <h3 style="text-align: center">Fin</h3>

                </b-col>
              </b-row>

            </div>
          </template>
        </date-picker>
      </b-form-group>
      <label for="textarea-default">Notas</label>
      <b-form-textarea
          v-model="appointment.description"
          id="textarea-default"
          placeholder="Notas"
          rows="3"
      />
      <b-row class="mt-3" v-if="verified_treatment && appointment.appoint_person_id">
        <b-col cols="2">
          <b-form-checkbox
              v-model="checked_recurrent"
              @input="changeChecked"
          >
            Recurrencia
          </b-form-checkbox>

        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Citas"
              label-for="start-date"
          >
            <b-form-input
                v-model="appointment_days"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Frecuencia"
              label-for="start-date"
          >
            <b-form-input
                v-model="appointment_days_interval"
            >
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-button class="mt-1"
                    @click="changeChecked"
                    v-ripple.400="'rgba(25, 17, 46, 0.15)'"
                    type="button"
                    variant="outline-secondary"
          >
            Verificar
          </b-button>
        </b-col>

      </b-row>
      <b-row v-if="appointments_array.length > 0" v-for="(a, idx) in appointments_array" :key="idx">
        <b-col cols="6">
          <b-form-group
              label=""
              label-for="start-date"
          >
            <label for=""><span style="margin-left: 20px">Hora de inicio</span> <span style="margin-left: 20px">~</span>
              <span style="margin-left: 20px">Hora de salida</span></label>
            <date-picker
                v-model="a.value"
                format="YYYY-MM-DD HH:mm"
                type="datetime"
                :show-time-header="true"
                style="width: 100%"
                disabled="disabled"
                confirm
                range
                :disabled-date="(date) => date <= disabledBefore"
                placeholder="Seleccione las fechas"
                :bootstrap-styling="true"
            >
              <template #header>
                <div style="text-align: left">
                  <b-row>
                    <b-col cols="6">
                      <h3 style="text-align: center"> Inicio</h3>

                    </b-col>
                    <b-col cols="6">
                      <h3 style="text-align: center">Fin</h3>

                    </b-col>
                  </b-row>

                </div>
              </template>
            </date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              v-if="a.available"
              label="Sala"
              label-for="start-date"
          >
            <b-form-input
                v-model="a.name_place"
                disabled="disabled"
            />

          </b-form-group>
          <b-form-group
              v-else
              label="Sala"
              label-for="start-date"
          >
            <b-form-select
                @change="verifyDateRecurrent(idx)"
                v-model="a.place"
                :options="medicals_places"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="6">
          <b-form-group
              v-if="verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(1)"
                id="file1"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              v-if="verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(2)"
                id="file2"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              v-if="verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(3)"
                id="file3"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              v-if="verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(4)"
                id="file4"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="existPrimaryFileDownload.name">
          <div>
            <div align="center">
              <h3>Descargar Archivo</h3>

              <a @click="downloadsFile(existPrimaryFileDownload.id)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br>
                {{ existPrimaryFileDownload.name }}
              </a>

            </div>
          </div>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
              @change="medicalVerifyPaxman()"
              v-model="appointment.paxman"
              :value="appointment.paxman ? true : ''"
          >
            Paxman
          </b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox
            v-model="appointment.pago_directo"
            :value="appointment.pago_directo ? true : ''"
          >
            Pago Directo
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
          <b-form-checkbox
              @change="medicalVerifyPaxman()"
              v-model="appointment.reiki"
              :value="appointment.reiki ? true : ''"
          >
            Reiki
          </b-form-checkbox>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox
              @change="medicalVerifyPaxman()"
              v-model="appointment.functional_therapy"
              :value="appointment.functional_therapy ? true : ''"
          >
            Terapia Funcional
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button class="mt-1"
                v-if="available_appointent"
                v-ripple.400="'rgba(25, 17, 46, 0.15)'"
                type="submit"
                variant="outline-secondary"
      >
        Guardar Cita
      </b-button>

    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay, BSidebar, BFormInvalidFeedback, BFormSelect, BFormFile, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import moment from 'moment';
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required, url} from "@core/utils/validations/validations";
import DatePicker from 'vue2-datepicker';
import {createTimeReal} from "@/sockets/socket";

export default {
  name: "NewAppoitment",
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver, VueAutosuggest, BFormSelect, BOverlay, BFormFile,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCard,
    BLink,
    BRow,
    BCol, DatePicker, BFormCheckbox, BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reasons:[],
      appointments_array: [],
      reason_appoinment: {id: "", value: "Seleccione una razón"},
      checked_recurrent: false,
      appointment_days: '',
      appointment_days_interval: '',
      verified_treatment: false,
      verified_treatment_medicals: false,
      verified_treatment_edit: false,
      new_file: false,
      select_medical: {id: '', value: ''},
      select_group: {id: '', value: ''},
      select_price_list: {id: '', value: ''},
      types: [],
      select_types: {id: '', value: ''},
      sources: [{value: '', text: 'Seleccione una opción'},
      ],
      patients: [],
      value2: [new Date(this.hoursCalendar(moment().format('YYYY-MM-DD HH:mm'))), new Date(this.hoursCalendarHour(moment().format('YYYY-MM-DD HH:mm')))],
      sidebar_status: false,
      show: false,
      groups: [],
      pricelists: [],
      medicals: [],
      medicals_places: [],
      available_appointent: true,
      place_active: false,
      disabledBefore: '',
      clinical_states: [
        {value: 'p', text: 'Programa'},
        {value: 'r1', text: 'Revisión coordinador médico'},
        {value: 'r2', text: 'Revisión enfermería'},
        {value: 'rc', text: 'Revisión Conjunta'},
        {value: 'e', text: 'Envío de aseguradora/ cotizaciones'},
        {value: 'f', text: 'Farmacia'},
      ],
      partner: {},
      type_appointment: '',
      existPrimaryFileDownload: {id: null, name: null},
      type_appointment_person: '',
      appointment: {
        id: null,
        customer: '',
        appoint_person_id: '',
        description: '',
        app_dt_start: '',
        app_dt_stop: '',
        appoint_date: '',
        appoint_state: 'new',
        pricelist_id: '',
        currency_id: 33,
        appoint_state_clinical: "p",
        appoint_group_id: '',
        time_slot: '',
        time_slot_day: '',
        source: '',
        clinic_type_appointment_id: null,
        treatment_file_txt: null,
        treatment_file_txt2: null,
        treatment_file_txt3: null,
        treatment_file_txt4: null,
        treatment_file: null,
        treatment_file2: null,
        treatment_file3: null,
        treatment_file4: null,
        cycle: null,
        paxman: false,
        reiki: false,
        functional_therapy: false,
        pago_directo: false,
        reason_appointment: null,
      },
      required,
      email,
      url,
    }
  },
  async created() {
    const day = moment().subtract(1, 'day').format('DD-MM-YYYY')
    this.disabledBefore = this.$moment(day, 'DD-MM-YYYY')
    this.type_appointment = this.$route.params.type
    this.type_appointment_person = this.$route.params.user
    if (this.getUser.appointee_id) {

      if (this.type_appointment === 'tratamiento') {
        this.verified_treatment_medicals = true

      } else {
        this.verified_treatment_medicals = false

        this.appointment.appoint_person_id = this.getUser.appointee_id
        this.select_medical.id = this.getUser.appointee_id
      }
    } else {
      this.verified_treatment_medicals = true
      this.appointment.appoint_person_id = null
      this.sources = []
      this.patients = []
      this.verified_treatment = false
      this.verified_treatment_edit = false
      this.new_file = false
      this.select_medical = {id: '', value: ''}
      this.select_group = {id: '', value: ''}
      this.select_price_list = {id: '', value: ''}
    }
    if (this.$route.query.date){
      this.value2 = [new Date(moment(this.$route.query.date).format('YYYY-MM-DD HH:mm')), new Date(this.hoursCalendar(moment(this.$route.query.date).format('YYYY-MM-DD HH:mm')))]
      this.appointment.app_dt_start = moment(this.$route.query.date).format('YYYY-MM-DD HH:mm')
      this.appointment.app_dt_stop = this.hoursCalendar(moment(this.$route.query.date).format('YYYY-MM-DD HH:mm'))
    }else{
      this.appointment.app_dt_start = this.hoursCalendar(moment().format('YYYY-MM-DD HH:mm'))
      this.appointment.app_dt_stop = this.hoursCalendarHour(moment().format('YYYY-MM-DD HH:mm'))
    }

    if (this.type_appointment === 'tratamiento') {
      await this.verifyDate('')
      await this.allReasons()
      await this.getMedicalSelect()
      await this.getGroupSelect()
      await this.getSource()
    } else {
      await this.getGroupSelect()
      await this.getMedicalSelect()
      await this.allReasons()
      await this.getSource()
      await this.verifyDate('')
      await this.getPriceListSelect()
      await this.getType()
    }


  },
  methods: {
    ...mapActions('patient', ['findPatientsSearchSelect', 'findPricelistId', 'findPatientOne', 'searchPrimaryFile', 'downloadFiPatientOne']),
    ...mapActions('calendarStoreModule', ['fetchEvents']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentPriceList', 'findAppointmentGroups', 'appointmentAvailable',
      'findSlotVerify', 'createAppointmentSend', 'findAppointmentSource', 'findAppointmentUserOne', 'findApointeeSearch', 'downloadFileAppointment',
      'deleteFile', 'findApointeeDoctorGroup',
      'findAppointmentVerify', 'getTypeAppointment', 'appointmentAvailablePaxman','getListAppointment']),
    async changeDate() {
      this.appointment.app_dt_start = moment(this.value2[0]).format('YYYY-MM-DD HH:mm:ss')
      this.appointment.app_dt_stop = moment(this.value2[1]).format('YYYY-MM-DD HH:mm:ss')
      await this.verifyDate('')
    },

    async changeReason(){
      this.appointment.reason_appointment = this.reason_appoinment.id;
    },

    async allReasons(){
      const response = await this.getListAppointment();
      this.reasons = []
      for (const res of response){
        this.reasons.push({
          id:res.value,
          value:res.value,
        })
      }

    },

    async handleImage(id) {
      const file = document.querySelector(`#file${id}`).files[0]
      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        if (id === 1) {
          this.appointment.treatment_file = event.target.result
          this.appointment.treatment_file_txt = file.name
        } else if (id === 2) {
          this.appointment.treatment_file2 = event.target.result
          this.appointment.treatment_file_txt2 = file.name
        } else if (id === 3) {
          this.appointment.treatment_file3 = event.target.result
          this.appointment.treatment_file_txt3 = file.name
        } else {
          this.appointment.treatment_file4 = event.target.result
          this.appointment.treatment_file_txt4 = file.name
        }

        /* this.company.file_key = event.target.result.split(',')[1]*/
      };
    },
    async changeChecked() {
      if (this.checked_recurrent) {
        if (!this.appointment_days) {
          return
        }
        if (!this.appointment_days_interval) {
          return
        }
        this.medicals_places = []
        const send = {
          start: this.appointment.app_dt_start,
          end: this.appointment.app_dt_stop,
          place: this.appointment.appoint_person_id,
          name_place: this.select_medical.value,
          date: this.appointment.appoint_date,
          days: parseInt(this.appointment_days),
          interval: parseInt(this.appointment_days_interval),
          appoint_group_id: this.appointment.appoint_group_id,
          appoint_state: this.appointment.appoint_state,
          customer: this.appointment.customer,
          pricelist_id: this.appointment.pricelist_id,
          source: this.appointment.source,
          appoint_state_clinical: this.appointment.appoint_state_clinical,
          currency_id: this.appointment.currency_id,
          cycle: this.appointment.cycle
        }
        this.appointments_array = []
        const response = await this.findAppointmentVerify(send);

        for (const b of response) {
          this.appointments_array.push({
            id: null,
            place: b.place,
            name_place: b.name_place,
            available: b.available,
            time_slot_day: b.time_slot_day,
            date: b.date,
            cycle: b.cycle,
            time_slot: b.time_slot,
            date_start: b.date_start,
            date_end: b.date_end,
            customer: b.customer,
            appoint_state: b.appoint_state,
            appoint_group_id: b.appoint_group_id,
            pricelist_id: b.pricelist_id,
            source: b.source,
            appoint_state_clinical: b.appoint_state_clinical,
            currency_id: b.currency_id,
            value: [new Date(b.date_start), new Date(b.date_end)]
          })
        }
        for (const v of this.appointments_array) {
          if (v.available) {

          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Revice sus citas no esta disponible',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            return
          }

        }

        this.medicals_places.push({
          value: '', text: 'Selecciona una sala disponible'
        })
        for (const a of this.medicals) {
          if (a.id !== this.appointment.appoint_person_id) {
            this.medicals_places.push({
              value: a.id, text: a.value
            })
          } else {

          }
        }
        return
      }
    },
    async deleteFileAppointment(id) {
      const response = await this.deleteFile(id)
      this.show = true
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se borro el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.new_file = true
        this.verified_treatment = false
      } else {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al borrar el archivo',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
      this.show = false
    },
    async downloads(id, file, name, partner) {
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner
      }
      await this.downloadFileAppointment(send)
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async verifySource() {
      let name_source;
      for (const a of this.sources) {
        if (a.value === this.appointment.source) {
          name_source = a.text
        }
      }
      if (name_source === 'Tratamiento') {
        let new_group = []
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (a.is_place) {
            this.select_group = {id: a.id, value: a.group_name}
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }
        }
        this.groups = []
        this.groups = new_group
        await this.searchGroup(this.select_group.id)
        this.appointment.appoint_group_id = this.select_group.id
        this.place_active = true
        this.verified_treatment = true

      } else {
        let new_group = []
        this.medicals = []
        this.appointment.treatment_file = null
        this.select_group = {id: '', value: ''}
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (!a.is_place) {
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }

        }
        if (this.type_appointment_person === 'doctor') {
          const gro = await this.findApointeeDoctorGroup(this.appointment.appoint_person_id)
          this.appointment.appoint_group_id = gro.appoint_group_id
        }
        this.verified_treatment = false
        this.groups = []
        this.groups = new_group
        this.place_active = false
      }
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async verifyAppointment(id) {
      const response = await this.findAppointmentUserOne(id);

      const dat1 = this.resHoursOdoo(response.app_dt_start)
      const dat2 = this.resHoursOdoo(response.app_dt_stop)
      this.appointment = response

      this.appointment.app_dt_start = dat1
      this.appointment.app_dt_stop = dat2
      this.partner = response.ResPartner;
      this.select_medical.id = response.ResPartnerAppointment.id
      this.select_medical.value = response.ResPartnerAppointment.name
      this.select_group.id = response.AppointmentGroup.id
      this.select_group.value = response.AppointmentGroup.group_name
      if (response.AppointmentGroup.is_place) {
        if (response.treatment_file) {
          this.verified_treatment = true
        } else {
          this.new_file = true
          this.verified_treatment = false
        }
      }


      delete this.appointment.ResPartnerAppointment
      delete this.appointment.ResPartner
      delete this.appointment.ProductPricelist
      delete this.appointment.AppointmentGroup
      delete this.appointment.AppointmentSource
    },
    async verifyDate(type) {

      if (this.appointment.app_dt_start) {
        const separate = this.appointment.app_dt_start.split(' ')
        this.appointment.appoint_date = separate[0]
        this.appointment.time_slot_day = this.dateString(separate[0])
        this.appointment.time_slot = await this.getSlot(this.appointment.time_slot_day)
        if (!this.appointment.time_slot) {
          this.available_appointent = false
          return
        }
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop && this.appointment.appoint_person_id) {

          await this.medicalVerify();
          if (this.appointment.paxman === '') {
            await this.medicalVerifyPaxman();
          }

        }
      }

    },

    async medicalVerifyPaxman() {
      if (this.appointment.paxman === '') {
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

          const send = {
            start: this.sumHoursOdoo(this.appointment.app_dt_start),
            end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          }
          const response = await this.appointmentAvailablePaxman(send);

          if (!response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `No hay citas en esta Fecha para paxman`,
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.available_appointent = response
          } else {
            this.available_appointent = response
          }
        }
      }else{
        this.available_appointent = true
      }
    },
    async verifyDateRecurrent(index) {
      this.appointments_array[index].name_place = '';
      for (const a of this.medicals_places) {
        if (a.value === this.appointments_array[index].place) {
          this.appointments_array[index].name_place = a.text
        }
      }
      const separate = this.appointments_array[index].date_start.split(' ')
      this.appointments_array[index].time_slot_day = separate[0]
      this.appointments_array[index].time_slot_day = this.dateString(separate[0])
      this.appointments_array[index].time_slot = await this.getSlot(this.appointments_array[index].time_slot_day);
      const verify = await this.medicalVerifyRecurrent(this.appointments_array[index].date_start, this.appointments_array[index].date_end, this.appointments_array[index].place)
      this.appointments_array[index].available = verify;
    },
    async medicalVerifyRecurrent(start, end, place) {
      const send = {
        start: this.sumHoursOdoo(start),
        end: this.sumHoursOdoo(end),
        medical_id: place
      }
      const response = await this.appointmentAvailable(send);

      if (!response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `No hay citas en esta Fecha`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.available_appointent = response
        return false
      } else {
        this.available_appointent = response
        return true
      }
    },
    async getSlot(day) {
      if (day) {
        const response = await this.findSlotVerify(day.toLowerCase());
        if (response) {
          this.available_appointent = true
          return response
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Este dia no esta disponible',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = false
          return false
        }
      }


    },
    async verifiedForm() {
      if (this.appointment.customer === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo paciente requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.appointment.app_dt_start === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo hora inicio requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.appointment.app_dt_stop === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo hora salida requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.appointment.appoint_person_id) {
        if (this.type_appointment === 'consulta') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo doctor  requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo sala  requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        }

      }
      if (!this.appointment.pricelist_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Tipo de paciente  requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.appointment.reason_appointment) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Motivo de cita es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      return true
    },

    async createAppointment(appointment) {

      let res;
      let res_error;
      if (appointment === 'create') {
        res = 'creada'
        res_error = 'crear'
      } else {
        res = 'editada'
        res_error = 'editar'
      }
      const e = await this.verifiedForm()
      if (!e) {
        return
      }
      this.show = true
      this.appointment.paxman = this.appointment.paxman === ''? true: false
      this.appointment.functional_therapy = this.appointment.functional_therapy === ''? true: false
      this.appointment.reiki = this.appointment.reiki === ''? true: false
      this.appointment.pago_directo = this.appointment.pago_directo === ''? true: false

      const response = await this.createAppointmentSend(this.appointment)
      if (response) {
        if (this.checked_recurrent) {
          let send_appointment = [];
          for (const b of this.appointments_array) {
            if (b.available) {
              send_appointment.push({
                id: null,
                customer: b.customer,
                reason_appointment: this.appointment.reason_appointment,
                cycle: this.appointment.cycle,
                description: this.appointment.description,
                appoint_person_id: b.place,
                app_dt_start: this.sumHoursOdooV(b.date_start),
                app_dt_stop: this.sumHoursOdooV(b.date_end),
                appoint_date: b.date,
                appoint_state: b.appoint_state,
                pricelist_id: b.pricelist_id,
                currency_id: b.currency_id,
                appoint_state_clinical: b.appoint_state_clinical,
                appoint_group_id: b.appoint_group_id,
                time_slot: b.time_slot,
                time_slot_day: b.time_slot_day,
                source: b.source,
                paxman: false,
                pago_directo:false,
                treatment_file: this.appointment.treatment_file,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error verifique la disponibilidad`,
                  icon: 'error',
                  variant: 'warning',
                },
              });
              return
            }
          }
          const response_all = await this.createAppointmentSend(send_appointment)
          if (response_all) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Citas reservadas`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.show = false
            this.appointment = {}
            this.partner = {}
            await createTimeReal(true)
            if (this.type_appointment === 'consulta') {
              await this.$router.push('/citas')
            } else {
              await this.$router.push('/salas')
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error al generar las citas recurrentes`,
                icon: 'error',
                variant: 'warning',
              },
            });
          }

        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Cita ${res}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.show = false
          this.appointment = {}
          this.partner = {}
          await createTimeReal(true)
          if (this.type_appointment === 'consulta') {
            await this.$router.push('/citas')
          } else {
            await this.$router.push('/salas')
          }
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al ${res_error} cita`,
            icon: 'error',
            variant: 'warning',
          },
        });
        this.show = false

      }
    },
    async downloadsFile(id) {
      const send = {
        id,
      }
      await this.downloadFiPatientOne(send);
    },
    async patientVerify(item) {
      this.appointment.customer = item.item.id;
      this.existPrimaryFileDownload = {id: null, name: null};
      const response = await this.findPricelistId(this.appointment.customer)
      const verifyChemo = await this.findPatientOne(this.appointment.customer);
      // const verifyExistFile = await this.searchPrimaryFile(this.appointment.customer);
      // if (verifyExistFile.file){
      //   this.existPrimaryFileDownload = {id:verifyExistFile.file.id,name:verifyExistFile.file.file};
      // }
      this.appointment.pricelist_id = response
      if (verifyChemo.chemo_suspension) {
        this.available_appointent = false;

        this.$swal({
          icon: 'warning',
          title: `Quimioterapia supendida: ${verifyChemo.reason_suspension}`,
          text: `El paciente esta suspendido por: ${verifyChemo.comments_suspension ? verifyChemo.comments_suspension : 'Quimioterapia Suspendida!'} `,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        return
      }
      this.available_appointent = true
    },
    async getPatientSelect(text) {

      if (text === '' || text === undefined) {
        this.patients = []
        return
      }
      this.patients = []
      let cou = []
      const response = await this.findPatientsSearchSelect(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.patients = [{
        data: cou,
      }]

    },
    async medicalVerify() {

      this.appointment.appoint_person_id = this.select_medical.id;
      if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

        const send = {
          start: this.sumHoursOdoo(this.appointment.app_dt_start),
          end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          medical_id: this.appointment.appoint_person_id
        }
        const response = await this.appointmentAvailable(send);

        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No hay citas en esta Fecha`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = response
        } else {
          this.available_appointent = response
        }
      }
    },

    async groupsVerify() {
      this.appointment.appoint_group_id = this.select_group.id;
      await this.searchGroup(this.select_group.id)
    },
    async typesVerify() {
      this.appointment.clinic_type_appointment_id = this.select_types.id;
    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups();
      console.log(response)
      for (const a of response) {
        this.groups.push({
          id: a.id,
          value: a.group_name,
          verifid: a.is_place
        })
      }
    },
    async getMedicalSelect() {
      this.medicals = []
      const response = await this.findAppointmentDoctor();
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getSource() {
      const ro = this.$route.params.type

      let id;
      const response = await this.findAppointmentSource()

      for (const a of response) {
        if (a.name === this.capitalize(ro)) {
          id = a.id
          this.sources.push({
            value: a.id,
            text: a.name,
          })
        }
      }
      this.appointment.source = id

      await this.verifySource()
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getType() {

      this.pricelists = []
      const response = await this.getTypeAppointment()
      for (const a of response) {
        this.types.push({
          id: a.id,
          value: a.name,
        })
      }
    },
  }
  ,
  computed: {
    ...
        mapGetters('auth', ['getUser']),
    disabledDate(date) {
      console.log(date)
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
